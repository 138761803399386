<template>
    <b-row>
        <b-col>
            <zw-table ref="table"
                      tableName="bank_transfers"
                      columnsPrefix="bank_transfers.column."
                      base-table="bank_transactions"
                      :items="myProvider"
                      :fields="fields"
                      :filter="filter"
                      :selected="selected"
                      title="common.menu.account-book.bank-transfers"
                      :actions-list="getActions()"
                      :sticky="false"
                      sticky-header="100%"
            >
            </zw-table>
        </b-col>
    </b-row>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import moment from "moment";

export default {
    name: 'Credit',
    mixins: [commonTable],
    props: {
        'month': [String],
    },
    data() {
        return {
            tableOptions: {
                sortBy: 'date_time',
                sortDesc: true,
            },
            tableUniqKey: 'bank_credit',
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('BankTransfers', ['getTransfersTable', 'getTransfer']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'BankTransfers/fetchTransfers', 'getTransfersTable');
        },
        getActions() {
            return [
                {
                    title: this.$t('bank.button.sync_transactions'),
                    icon: 'sync',
                    click: () => {
                        this.$root.$children[0].openModal('bank-transfers', {}, null, {width: '800px'})
                    }
                },
            ]
        },
    },
    mounted() {
        let monthStart = moment('01.' + this.month, 'DD.MM.YYYY').startOf('month').format('DD.MM.YYYY')
        let monthEnd = moment('01.' + this.month, 'DD.MM.YYYY').endOf('month').format('DD.MM.YYYY')

        this.$set(this.filter, 'date_time', monthStart + ';' + monthEnd)
    },
    watch: {
        month: function (val) {
            let monthStart = moment('01.' + this.month, 'DD.MM.YYYY').startOf('month').format('DD.MM.YYYY')
            let monthEnd = moment('01.' + this.month, 'DD.MM.YYYY').endOf('month').format('DD.MM.YYYY')

            this.$set(this.filter, 'date_time', monthStart + ';' + monthEnd)
        },
    }
}
</script>